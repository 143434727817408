export enum RoutePathType {
  AdminPanel = "admin-panel",
  AllFiles = "all-files",
  AllSystems = "all-systems",
  Documentation = "documentation",
  File = "file",
  FunctionAuthCallback = "callback",
  MagicDocs = "magic-docs",
  NotFound = "404",
  Root = "/",
  PATs = "personal-access-tokens",
  RegistryInfo = "registry-information",
  Settings = "settings",
  SignIn = "sign-in",
  System = "system",
  Upload = "upload",
}
