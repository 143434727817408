import React from "react";

import { LoadingSpinner } from "~/assets/svg";
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip";

export type ButtonVariant =
  | "default"
  | "primary"
  | "secondary"
  | "ternary"
  | "outlined"
  | "transparent"
  | "danger"
  | "warning";

type ButtonProps = {
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  tooltip?: string;
  label: string;
  loading?: boolean;
  small?: boolean;
  startIcon?: React.ReactNode;
  variant: ButtonVariant;
  onClick: () => void;
};

const Button = ({
  className = "",
  dataTestId,
  disabled = false,
  endIcon,
  fullWidth,
  tooltip = "",
  label,
  loading = false,
  small = false,
  startIcon,
  variant = "default",
  onClick,
}: ButtonProps) => {
  const buttonStyleBase =
    "flex items-center justify-center max-w-full gap-x-8px px-12px rounded border-none cursor-pointer whitespace-pre truncate";
  const buttonStyle = `${buttonStyleBase} py-8px`;
  const buttonHeight = small ? "h-8" : "h-10";
  const fullWidthStyle = fullWidth ? "w-full" : "";
  const textSize = small ? "text-sm" : "text-base";
  const variantStyles = {
    default: "color-white-0 bg-gray-800 hover:bg-gray-900",
    primary: "color-white-0 bg-primary-400 hover:bg-primary-500",
    secondary:
      "border border-solid border-width-2px border-primary-500 bg-white-0 color-primary-500 hover:bg-primary-50",
    ternary: "color-gray-800 bg-gray-100 hover:bg-gray-50",
    outlined: "color-gray-700 bg-white-0 border border-solid border-gray-300 hover:bg-primary-50",
    transparent: "color-primary-500 bg-transparent hover:bg-gray-50",
    danger: "color-white-0 bg-red-400 hover:bg-red-300",
    warning: "color-red-400 bg-red-100 hover:bg-red-50",
  };

  const tooltipBtnComponent = (
    <span
      className={`${buttonStyleBase} ${fullWidthStyle} ${buttonHeight} ${textSize} ${variantStyles[variant]} ${className}`}
      data-testid={dataTestId}
    >
      {loading ? <LoadingSpinner width="16px" height="16px" /> : startIcon || null}
      {label}
      {endIcon && endIcon}
    </span>
  );

  if (tooltip) {
    return (
      <Tooltip>
        <TooltipTrigger disabled={disabled || loading} onClick={onClick}>
          {tooltipBtnComponent}
        </TooltipTrigger>
        <TooltipContent>{tooltip}</TooltipContent>
      </Tooltip>
    );
  }
  return (
    <button
      className={`${buttonStyle} ${fullWidthStyle} ${buttonHeight} ${textSize} ${variantStyles[variant]} ${className}`}
      data-testid={dataTestId}
      disabled={disabled || loading}
      type="button"
      onClick={onClick}
    >
      {loading ? <LoadingSpinner width="16px" height="16px" /> : startIcon || null}
      {label}
      {endIcon && endIcon}
    </button>
  );
};

export default Button;
