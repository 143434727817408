import React from "react";
import { toast } from "react-hot-toast";

import { CheckCircleEmpty, Close, ErrorOutline, Info, XCircle } from "../../assets/svg";
import IconButton from "../IconButton";

export type ToastType = "error" | "success" | "alert" | "info" | "dark" | "light";

const BG_COLORS = {
  error: "bg-red-300",
  success: "bg-secondary-400",
  alert: "bg-yellow-400",
  info: "bg-primary-400",
  dark: "bg-gray-900",
  light: "bg-white-0",
};

const COLOR = {
  error: "text-white-0",
  success: "text-white-0",
  alert: "text-gray-800",
  info: "text-white-0",
  dark: "text-white-0",
  light: "text-gray-800",
};

interface ToastProps {
  message: string;
  subMessage?: string;
  type?: ToastType;
  clearToast?: () => void;
}

export const Toast = ({ message, subMessage, type = "success", clearToast }: ToastProps) => {
  const getIcon = (toastType: ToastType) => {
    switch (toastType) {
      case "error":
        return <XCircle color="#fff" />;
      case "success":
        return <CheckCircleEmpty color="#fff" />;
      case "alert":
        return <ErrorOutline color="#000" />;
      default:
        return <Info color="#fff" />;
    }
  };

  return (
    <div
      data-testid="istari-toast-container"
      className={`px-16px py-12px rounded max-w-480px ${BG_COLORS[type as ToastType]} ${COLOR[type as ToastType]}`}
    >
      <div className="flex gap-x-8px items-center place-content-between">
        <div className="flex gap-x-8px items-center">
          {getIcon(type as ToastType)}

          <div className="gap-5px">
            <div className="subtitle2 whitespace-pre-line">{message}</div>

            {!!subMessage && <div className="text-sm">{subMessage}</div>}
          </div>
        </div>

        <div className="flex items-center ml-12px">
          <IconButton
            dataTestId="istari-toast-clear-button"
            icon={<Close color={type === "light" || type === "alert" ? "#000" : "#fff"} width="20px" height="20px" />}
            label="Dismiss alert"
            variant="transparent"
            onClick={clearToast!}
          />
        </div>
      </div>
    </div>
  );
};

export const showToast = (message: string, type: ToastType) => {
  toast.custom((tos) => <Toast message={message} type={type} clearToast={() => toast.remove(tos.id)} />, {
    position: "bottom-right",
  });
};

export default showToast;
